<template>
    <div class="table-responsive px-4 mt-3">
        <table :id="tableId" class="table table-bordered table-responsive-md table-striped text-center">
            <thead>
                <tr>
                    <th v-for="header in headers" :key="header">{{ header }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
// Import jQuery and DataTables
import $ from 'jquery';
import 'datatables.net';

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
     
        tableId: {
            type: String,
            required: true,
        }
    },
    watch: {
        items: 'initDataTable'
    },
    methods: {
        initDataTable() {
            // Primero, destruye la instancia existente si la hay
            if ($.fn.DataTable.isDataTable(`#${this.tableId}`)) {
                $(`#${this.tableId}`).DataTable().destroy();
            }

            // Inicializa DataTables
            this.$nextTick(() => {
                $(`#${this.tableId}`).DataTable({
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                    },
                });
            });
        }
    },
    mounted() {
        this.initDataTable();
    }
};
</script>

<style>
h3 {font-weight: bold;}
h4 {color: #07459b; font-size:18px}
.textoProfesiograma, ol {font-size: 16px}
</style>
<template>
    <div>
        <card>
            <cards id="cargos-aceptados">
                <div class="form-group col-md-4">
                    <h5 for="Ubicacion" class=" mb-3"><b>PROFESIOGRAMA POR SUCURSAL</b></h5>
                    <label>Selecciona la Sucursal:</label>
                    <b-select type="text" class="form-control bg-dark personalizado" :options="listaSucursales"
                        v-model="selectedSucursal" value-field="id" text-field="descripcion"
                        @change="getSucursalDataProfesiograma" />
                </div>
                <DataTableButtons :items="listaSucursalPrincipalProfesiograma"
                    :headers="headerSucursalPrincipalProfesiograma" :keys="principalSucursalProfesiogramaKeys"
                    tableId="datatable_PrincipalSucursalProfesiograma" :actions1="showNotaAnexo"
                    button1="fa-regular fa-clipboard" titleButton1="Ver documentación"
                    :actions2="getDataProfesiogramaItems" button2="fa-solid fa-chalkboard-user"
                    titleButton2="Ver Profesiograma" parseDate="true">
                </DataTableButtons>
            </cards>
        </card>
        <card>
            <div>
                <card class="shadow-none" id="cargos-aceptados">
                    <template v-slot:headerTitle>
                        <span class="card-title my-2 h4"><b>TODOS LOS PROFESIOGRAMAS</b></span>
                    </template>
                    <DataTableButtons :items="listaPrincipalProfesiograma" :headers="headerPrincipalProfesiograma"
                        :keys="principalProfesiogramaKeys" tableId="datatable_PrincipalProfesiograma"
                        :actions1="showNotaAnexo" button1="fa-regular fa-clipboard" titleButton1="Ver documentación"
                        :actions2="getDataProfesiogramaItems" button2="fa-solid fa-chalkboard-user"
                        titleButton2="Ver Profesiograma" parseDate="true"/>
                </card>
            </div>
        </card>
        <b-modal size="xl" v-model="showModal" title="Documentación Profesiograma" hide-footer id="custom-modal"
            class="custom-modal">
            <DataTableArray :items="notas" :headers="headerNotas" tableId="datatable_Notas" />

            <DataTableArray :items="anexos" :headers="headerAnexos" tableId="datatable_Anexos" />

            <DataTableArray :items="observaciones" :headers="headerObservaciones" tableId="datatable_Observaciones" />
        </b-modal>
        <b-modal size="xl" v-model="showNewModal" title="PROFESIOGRAMA" hide-footer id="new-custom-modal"
            class="custom-modal">
            <h3 class="">
                <b>PROFESIOGRAMA: </b>{{ profesiogramaVersion.sucursal }} <br />

                <b>VERSION: </b>{{ profesiogramaVersion.version }}
            </h3>

            <card class=" col-sm-12  p-5 hover bg-dark py-2" v-for="(item, index) in processedProfesiogramaItems" :key="index">
                <div class="">
                    <div @click="toggleCollapse(index)" style="cursor: pointer;" class="d-flex justify-content-between  bg-dark py-2">
                        <h3 class=" mb-4">
                            <b>CATEGORIA </b>{{ item.categoria }}
                        </h3>
                        <h2 v-show="isCollapsed[index]"><i class="fa-solid fa-caret-down"></i></h2>
                        <h2 v-show="!isCollapsed[index]"><i class="fa-solid fa-caret-up"></i></h2>
                    </div>

                    <div v-show="!isCollapsed[index]" class=" bg-dark py-2 row">
                        <div class=" col-sm-12 col-lg-3 mb-4 bg-dark py-2 ">
                            <h3> NIVEL DEL CARGO </h3>
                            <p class=" textoProfesiograma">{{ getNivelCargoDescripcion(index) }}</p>
                        </div>
                        <div class=" col-sm-12 col-lg-4  mb-4 bg-dark py-2">
                            <h3> CARGOS ASOCIADOS </h3>
                            <p class=" textoProfesiograma" v-for="(valor, indice) in listaCargosFiltrados[index]" :key="indice">{{ valor.nombre
                                }}
                            </p>
                        </div>
                        <div class=" col-sm-5  mb-4 bg-dark py-2 ">
                            <h3> TEST PSICOLÓGICO INGRESO </h3>
                            <p class=" textoProfesiograma">{{ item.evaluacionPsicologicaIngreso }}</p>
                        </div>
                        <div class=" col-sm-12  mb-4 bg-dark py-2 ">
                            <h3>PELIGROS DEL(LOS) CARGO(S): </h3>
                            <div v-if="processedProfesiogramaRiesgos[index]" class="row justify-content-between px-5 py-2">
                                <div v-for="(peligros, grupo) in processedProfesiogramaRiesgos[index].peligrosPorGrupo"
                                    :key="grupo">
                                    <h4><b>{{ grupo }}</b></h4>
                                    <ul>
                                        <ol v-for="peligro in peligros" :key="peligro">{{ peligro }}</ol>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class=" col-sm-12  mb-4 text-center bg-dark py-2 ">
                            <h3>EXAMEN MÉDICO OCUPACIONAL CON ÉNFASIS OSTEOMUSCULAR </h3>
                            <div class="row d-flex justify-content-around mt-2">
                                <h4><b>INGRESO/ PREOCUP:</b>
                                    <p class=" textoProfesiograma mt-2">{{ item.examenOsteomuscularIngreso }}</p>
                                </h4>
                                <h4><b>PERIÓDICO:</b>
                                    <p class=" textoProfesiograma mt-2">{{ item.examenOsteomuscularPeriodico }}</p>
                                </h4>
                                <h4><b>RETIRO:</b>
                                    <p class=" textoProfesiograma mt-2" >{{ item.examenOsteomuscularRetiro }}</p>
                                </h4>
                            </div>
                        </div>

                        

                        <div class="row mb-4 bg-dark py-2 ">
                            <div class="col-md-4 d-flex flex-column">
                                <h3 class="text-center mb-2">PRUEBAS PARACLINICAS (INGRESO)</h3>
                                <div class="d-flex flex-column flex-grow-1">
                                    <h4><b>PRUEBAS ESPECIALIZADAS:</b> </h4>
                                    <div class="flex-grow-1 d-flex flex-column">
                                        <p class=" textoProfesiograma" v-for="(valor, clave) in item.pruebasParaclinicasIngresoEspecializadas"
                                            :key="clave">
                                            {{ valor }}
                                        </p>
                                    </div>
                                    <h4> <b>EXÁMENES DE LABORATORIO:</b></h4>
                                    <div class="flex-grow-1 d-flex flex-column">
                                        <p class=" textoProfesiograma" v-for="(valor, clave) in item.pruebasParaclinicasIngresoExamenes"
                                            :key="clave">
                                            {{ valor }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 d-flex flex-column">
                                <h3 class="text-center mb-2">PRUEBAS PARACLINICAS (PERIÓDICO)</h3>
                                <div class="d-flex flex-column flex-grow-1">
                                    <h4> <b>PRUEBAS ESPECIALIZADAS:</b></h4>
                                    <div class="flex-grow-1 d-flex flex-column">
                                        <p class=" textoProfesiograma" v-for="(valor, clave) in item.pruebasParaclinicasPeriodicoEspecializadas"
                                            :key="clave">
                                            {{ valor }}
                                        </p>
                                    </div>
                                    <h4><b>EXÁMENES DE LABORATORIO:</b></h4>
                                    <div class="flex-grow-1 d-flex flex-column">
                                        <p class=" textoProfesiograma" v-for="(valor, clave) in item.pruebasParaclinicasPeriodicoExamenes"
                                            :key="clave">
                                            {{ valor }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 d-flex flex-column">
                                <h3 class="text-center mb-2">PRUEBAS PARACLINICAS (RETIRO)</h3>
                                <div class="d-flex flex-column flex-grow-1">
                                    <h4><b>PRUEBAS ESPECIALIZADAS:</b></h4>
                                    <div class="flex-grow-1 d-flex flex-column">
                                        <p class=" textoProfesiograma" v-for="(valor, clave) in item.pruebasParaclinicasRetiroEspecializadas"
                                            :key="clave">
                                            {{ valor }}
                                        </p>
                                    </div>
                                    <h4><b>EXÁMENES DE LABORATORIO:</b></h4>
                                    <div class="flex-grow-1 d-flex flex-column">
                                        <p class=" textoProfesiograma" v-for="(valor, clave) in item.pruebasParaclinicasRetiroExamenes"
                                            :key="clave">
                                            {{ valor }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-sm-12  mb-4 bg-dark py-2 ">
                            <h3>ANEXOS </h3>
                            <p class=" textoProfesiograma" v-for="(valor, clave) in item.anexosQueAplican" :key="clave">
                                {{ valor }}</p>

                        </div>
                    </div>

                </div>
            </card>

        </b-modal>


    </div>


</template>


<script>
import DataTableButtons from "../../../../components/Datatable/DatatableButtons.vue";
import DataTableArray from "../../../../components/Datatable/DataTableArray.vue";
import { core } from "../../../../config/pluginInit";
import moment from "moment";
const Swal = require("sweetalert2");

export default {
    components: {
        DataTableButtons,
        DataTableArray
    },
    name: "DataTableContainer",
    data() {
        return {
            usuario: {},

            listaSucursales: [],
            selectedSucursal: {},
            listaPrincipalProfesiograma: [],
            listaSucursalPrincipalProfesiograma: [],
            listaProfesiogramaItems: [],
            listaTipoCargosFiltrados: [],
            listaCargosFiltrados: [],
            idProfesiograma: [],
            isCollapsed: {},

            headerPrincipalProfesiograma: ["Sucursal", "Versión", "Fecha", "Responsable", "Cargo del Responsable", "Licencia SO"],
            //"Notas", "Anexos"
            principalSucursalProfesiogramaKeys: ["nombreSucursalProfesiograma", "versionProfesiograma", "fechaVersionProfesiograma", "nombreCompleto", "cargo", "licenciaSo"],


            headerSucursalPrincipalProfesiograma: ["Sucursal", "Versión", "Fecha", "Responsable", "Cargo del Responsable", "Licencia SO"],
            principalProfesiogramaKeys: ["nombreSucursalProfesiograma", "versionProfesiograma", "fechaVersionProfesiograma", "nombreCompleto", "cargo", "licenciaSo"],

            // Modal data
            showNewModal: false,
            showModal: false,
            modalContent: "",
            itemId: null,

            notas: [],
            headerNotas: ["NOTA"],

            anexos: [],
            headerAnexos: ["ANEXOS"],

            observaciones: [],
            headerObservaciones: ["OBSERVACIONES"],

        };
    },
    async mounted() {
        this.$isLoading(true);
        core.index();
        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        const empresaId = this.usuario.empresa.id;

        await this.getPrincipalDataProfesiograma(empresaId);
        await this.getSucursales(empresaId);

        this.$isLoading(false);
    },

    computed: {
        profesiogramaVersion() {
            const profesiograma = this.listaPrincipalProfesiograma.find(item => item.id === this.idProfesiograma);
            if (profesiograma) {
                return {
                    sucursal: profesiograma.nombreSucursalProfesiograma,
                    version: profesiograma.versionProfesiograma
                };
            } else {
                return { sucursal: 'N/A', version: 'N/A' };
            }
        },

        processedProfesiogramaItems() {
            return this.listaProfesiogramaItems.map(item => {
                return {
                    ...item,

                    categoria: item.categoria,
                    nivelCargo: item.nivelCargo,
                    cargoVinculacionId: JSON.parse(item.cargoVinculacionId),
                    peligros: JSON.parse(item.peligros),
                    periodicidadExamen: item.periodicidadExamen,
                    examenOsteomuscularIngreso: item.examenOsteomuscularIngreso ? "Aplica" : "No aplica",
                    examenOsteomuscularPeriodico: item.examenOsteomuscularPeriodico ? "Aplica" : "No aplica",
                    examenOsteomuscularRetiro: item.examenOsteomuscularRetiro ? "Aplica" : "No aplica",
                    evaluacionPsicologicaIngreso: item.evaluacionPsicologicaIngreso,
                    pruebasParaclinicasIngresoEspecializadas: JSON.parse(item.pruebasParaclinicasIngresoEspecializadas),
                    pruebasParaclinicasIngresoExamenes: JSON.parse(item.pruebasParaclinicasIngresoExamenes),
                    pruebasParaclinicasPeriodicoEspecializadas: JSON.parse(item.pruebasParaclinicasPeriodicoEspecializadas),
                    pruebasParaclinicasPeriodicoExamenes: JSON.parse(item.pruebasParaclinicasPeriodicoExamenes),
                    pruebasParaclinicasRetiroEspecializadas: JSON.parse(item.pruebasParaclinicasRetiroEspecializadas),
                    pruebasParaclinicasRetiroExamenes: JSON.parse(item.pruebasParaclinicasRetiroExamenes),
                    anexosQueAplican: JSON.parse(item.anexosQueAplican),
                    sstProfesiogramaId: item.sstProfesiogramaId,
                };
            });
        },
        processedProfesiogramaRiesgos() {
            return this.listaProfesiogramaItems.reduce((acc, item, index) => {
                try {
                    const peligros = JSON.parse(item.peligros);
                    const peligrosPorGrupo = peligros.reduce((grupoAcc, factor) => {
                        if (!grupoAcc[factor.grupo]) {
                            grupoAcc[factor.grupo] = [];
                        }
                        grupoAcc[factor.grupo].push(factor.factor);
                        return grupoAcc;
                    }, {});

                    acc[index] = {
                        peligrosPorGrupo,
                    };
                } catch (error) {
                    console.error(`Error al procesar item ${index}:`, error);
                    acc[index] = {
                        peligrosPorGrupo: {}
                    };
                }
                return acc;
            }, {});
        }
    },

    methods: {

        toggleCollapse(colapsar) {
            this.$set(this.isCollapsed, colapsar, !this.isCollapsed[colapsar]);
        },


        getNivelCargoDescripcion(index) {
            if (this.listaTipoCargosFiltrados &&
                this.listaTipoCargosFiltrados[index] &&
                this.listaTipoCargosFiltrados[index].descripcion) {
                return this.listaTipoCargosFiltrados[index].descripcion;
            }
            return 'Descripción no disponible';
        },

        async getPrincipalDataProfesiograma(empresaId) {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstProfesiograma/GetSstProfesiograma/" + empresaId,
                });
                this.listaPrincipalProfesiograma = res.length > 0 ? res : [];
                //    console.log("Profesiogramas Obtenidos: ", this.listaPrincipalProfesiograma);
            } catch (error) {
                this.listaPrincipalProfesiograma = [];
                console.log("err", error);
            }
        },

        async getSucursalDataProfesiograma() {
            //console.log("Sucursal", this.selectedSucursal);
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstProfesiograma/GetSstProfesiogramaEmpresaSucursal/" + this.selectedSucursal + "/" + this.usuario.empresa.id,
                });
                if (res.length > 0) {
                    this.listaSucursalPrincipalProfesiograma = res

                    // console.log("LOS PROFESIOGRAMAS FILTRADOS POR SUCURSAL SON: ", this.listaSucursalPrincipalProfesiograma);
                }
                else {
                    Swal.fire({
                        title: "¡No hay Profesiogramas!",
                        text: "No hay Profesiogramas para esta Sucursal",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "OK",
                    })
                    this.listaSucursalPrincipalProfesiograma = [];
                    console.log("No hay profesiogramas para esta sucursal.");
                }


            } catch (error) {
                this.listaSucursalPrincipalProfesiograma = [];
                console.log("err", error);
            }

        },

        async getDataProfesiogramaItems(id) {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "SstProfesiograma/GetSstProfesiogramaItems/" + id,
                });
                this.listaProfesiogramaItems = res.length > 0 ? res : [];
                this.idProfesiograma = res[0].sstProfesiogramaId
                //console.log("Items del profesiograma Obtenidos: ", this.idProfesiograma);
                this.getTipoCargos();
            } catch (error) {
                this.listaProfesiogramaItems = [];
                console.log("err", error);
            }
            //console.log("ID PARA ITEMS DEL PROFESIOGRAMA ", id)
            this.showNewModal = true;
        },

        async showNotaAnexo(id) {
            //console.log("EL ID ES EL SIGUIENTE: ", id);

            // Encuentra la sucursal correspondiente al id
            let notasAnexos = this.listaPrincipalProfesiograma.find(item => item.id === id);

            if (notasAnexos) {
                //console.log("Sucursal encontrada: ", notasAnexos);
                // Aquí puedes acceder a las propiedades de la sucursal encontrada
                try {
                    this.notas = JSON.parse(notasAnexos.notasProfesiograma)
                    //console.log("NOTASAAAAAAAAA:", this.notas);
                }
                catch {
                    this.notas = []
                    console.log("No se pudieron obtener las NOTAS.");
                }
                try {
                    this.anexos = JSON.parse(notasAnexos.anexosProfesiograma)
                    //console.log("NOTASAAAAAAAAA:", this.anexos);
                }
                catch {
                    this.anexos = []
                    console.log("No se obtienen los ANEXOS.");
                }
                try {
                    this.observaciones = [notasAnexos.observacionesProfesiograma]
                    //console.log("OBSERVACIONES:", this.observaciones);
                }
                catch {
                    this.anexos = []
                    console.log("No se obtienen los ANEXOS.");
                }
                this.showModal = true;

            } else {
                alert("No se encontró un ANEXO con ese ID.")
                console.log("No se encontró un ANEXO con ese ID.");
            }
        },

        async getSucursales(empresaId) {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "Sucursal/ListSucursalEmpresa/" + empresaId,
                });

                this.listaSucursales = res.length > 0 ? res : [];
                //console.log("ID EMPRESA", empresaId);
                //console.log("LAS SUCURSALES SON ESTAS:", this.listaSucursales);
            } catch (error) {
                this.listaSucursales = [];
                console.log("err", error);
            }
        },

        async getTipoCargos() {

            this.listaTipoCargosFiltrados = [];
            this.listaCargosFiltrados = [];

            for (const item of this.listaProfesiogramaItems) {
                try {
                    // Accedemos directamente a nivelCargo
                    const nivelCargo = item.nivelCargo;
                    // Variable para guardar por cada iteración los cargos asociados al nivel
                    const cargosPorNivel = [];
                    // Parseamos el campo vinculacionId
                    const vinculacionIds = JSON.parse(item.cargoVinculacionId);

                    try {
                        let res = await this.$store.dispatch("hl_get", {
                            path: "TipoCargo/GetTipoCargo/" + nivelCargo,
                        });
                        this.listaTipoCargosFiltrados.push(res);

                        //console.log("El tipo de cargo es: ", this.listaTipoCargosFiltrados);
                    } catch (error) {
                        this.listaTipoCargosFiltrados = [];
                        console.log("err", error);
                    }



                    //console.log(`CARGOS VINCULADOS ANTES DE HACER EL CICLO: ${vinculacionIds}`);

                    // Iteramos sobre los IDs de vinculación parseados
                    for (const vinculacionId of vinculacionIds) {


                        try {
                            let res = await this.$store.dispatch("hl_get", {
                                path: "Cargo/GetCargoById/" + vinculacionId + "?extended=false",
                            });
                            cargosPorNivel.push(res);

                            //console.log("LOS CARGOS ASOCCIADOS AL NIVEL DE CARGO SON: ", cargosPorNivel);
                        } catch (error) {

                            console.log("err", error);
                        }


                    }

                    this.listaCargosFiltrados.push(cargosPorNivel);

                    //console.log('---', this.listaCargosFiltrados);
                } catch (error) {
                    console.error(`Error al procesar el item:`, error);
                }
            }
        }

    },

    filters: {
        formatDate(date){
            return date ? moment(date).locale("es").format('DD MM YYYY') :
            "No hay fecha registrada"
        }
    }
};
</script>
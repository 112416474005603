<template>

    <div class="custom-container">

        <listarProfesiograma/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import listarProfesiograma from "./ListarProfesiograma/listarProfesiograma.vue";

export default {

    components: {
        listarProfesiograma
    },

    computed: {
        ...mapGetters(["userLoggedIn"]),
    },


}

</script>